<template>
  <div class="change-tel">
    <back></back>
    <div class="f-23 text-center m-b-20">更换手机号码</div>
    <div class="text-center f-13">当前手机号： {{user.mobile}}</div>
    <van-form @submit="onSubmit" label-width="0em"
              validate-first>
      <div class="form">
        <div class="text-center f-11 m-b-25">每名用户每月仅可修改一次</div>
        <van-field
          v-model="tel"
          name="手机号"
          placeholder="请输入新手机号"
          type="number"
          :maxlength="11"
          :rules="[{ required: true, message: '请填写手机号' },{ pattern, message: '请输入正确的手机号' }]"/>
        <van-field
          v-model="code"
          name="验证码"
          placeholder="请填写验证码"
          :rules="[{ required: true, message: '请填写验证码' }]">
          <template #button>
            <span style="color: black;" v-show="!isShowTime" @click="getCode">验证码</span>
            <span style="color: black;" v-show="isShowTime">{{time}}s</span>
          </template>
        </van-field>
      </div>
      <div class="down">
        <van-button class="btn f-17"
                    :loading="isLoading"
                    native-type="submit" block>
          <span class="f-17">保存</span>
        </van-button>
      </div>
    </van-form>
    <clicaptcha v-if="isShowCheck" @success="success" @fail="isShowCheck = false" :tel="tel"></clicaptcha>
  </div>
</template>

<script>
import Back from '../components/Back'
import { mapMutations, mapState } from 'vuex'
import Clicaptcha from '../components/Clicaptcha'
import { changeTelApi, userApi } from '../api/user'
import { getWxConfig } from '../../tool'
export default {
  name: 'ChangeTel',
  components: { Back, Clicaptcha },
  data () {
    return {
      tel: '',
      code: '',
      isLoading: false,
      pattern: /^1\d{10}$/,
      isShowTime: false,
      isShowCheck: false,
      time: 60,
      timer1: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created() {
    getWxConfig('轻松小镇', '修改手机号')
  },
  destroyed () {
    clearInterval(this.timer1)
  },
  methods: {
    ...mapMutations({
      vxSetUser: 'setUser'
    }),
    async onSubmit (value) {
      this.isLoading = true
      try {
        const res = await changeTelApi({
          mobile: this.tel,
          sms_code: this.code
        })
        this.getUser()
        this.$toast(res.data.info)
        this.$emit('success', this.tel)
        this.isLoading = false
        this.getUser()
      } catch (e) {
        console.log(e)
        this.isLoading = false
        this.$toast.fail(e.data)
      }
    },
    success () {
      this.isShowCheck = false
      this.showCountdown()
      this.$toast('验证码已发送，请注意查收！')
    },
    showCountdown () {
      this.isShowTime = true
      this.timer = setInterval(() => {
        this.time--
        if (this.time <= 0) {
          clearInterval(this.timer)
          this.time = 60
          this.isShowTime = false
        }
      }, 1000)
    },
    async getCode () {
      if (!this.tel) {
        return this.$toast('请填写手机号')
      }
      this.showCheck()
      // try {
      //   const res = await codeApi({
      //     mobile: this.tel
      //   })
      //   console.log(res)
      //   this.$toast(res.data.info)
      //   this.showCountdown()
      // } catch (e) {
      //   console.log(e)
      //   this.$toast.fail(e.data)
      // }
    },
    showCheck () {
      this.isShowCheck = true
    },
    async getUser () {
      try {
        const res = await userApi({})
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user
        })
      } catch (e) {
        console.log(e)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-tel{
  height: 100vh;
  background: url("../assets/img/user-bg.png") no-repeat;
  background-size: 100%;
  padding-top: 45px;
  .form{
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 70px 40px;
    background: url("../assets/img/user-bg2.png") no-repeat;
    background-size: 100% 100%;
    /deep/ .van-cell{
      padding: 0;
      background-color: transparent;
      margin-bottom: 30px;
      align-items: center;
      &:after{
        display: none;
      }
      .van-field__label,.van-field__control{
        color: black;
      }
      .van-field__control{
        color: black;
        font-weight: 500;
      }

      .van-field__value {
        background: #FFFFFF;
        border-radius: 18px;
        padding: 5px 15px;
      }
    }
  }
  .down {
    padding: 10px 50px;
    .btn{
      padding: 15px;
      font-weight: 500;
      border-radius: 30px;
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #FFFFFF;
      box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
    }
  }
}
</style>
